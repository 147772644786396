import Vue         from 'vue';
import axios from 'axios';
import {each, get, remove, isArray, set, orderBy, isEmpty, indexOf} from "lodash-core";
import { v4 as uuidv4 } from 'uuid';
import {isbot} from "isbot";



export const state = () => ({
    gclid: null,
    clientUid: null,

    showGlobalDrawer: false,
    globalDrawerSection: null,

    showReleaseDetailMoreProducts: false,
    showPriceAlertDialog: false,


    showSettingDrawer: false,
    showSearchDrawer: false,
    showMegaMenu: false,
    showReleaseListFilter: false,
    megaMenuIndex: 0,
    mobileSearchDialogVisible: false,
    settingDialogVisible: false,
    mobileDrawerVisible     : false,
    loadedSneakers       : {},
    loadingMoreSneakers  : false,
    currency             : 'eur',
    onceLoggedIn         : false,
    location             : {},
    currencies           : {USD: '$ US Dollar', EUR: '€ Euro', JPY: '¥ Yen', GBP: '£ Pound'},
    currenciesSymbols    : {USD: '$', EUR: '€', JPY: '¥', GBP: '£'},
    currenciesCodes      : ['USD', 'EUR', 'JPY', 'GBP'],
    currenciesCodesLower : ['usd', 'eur', 'jpy', 'gbp'],
    searchString         : '',
    queries              : {
        filters: [],
        gender : [],
        sizes  : [],
        color  : [],
        brands : [],
        category: [],
        discount_from: 0,
        page   : 1,
        sort   : 'relevant'
    },
    landingQuery        :{

    },
    sizeFormat           : 'eu',
    brands               : [{"brand":"Nike","clicks":474,"slug":"nike"},{"brand":"Adidas","clicks":307,"slug":"adidas"},{"brand":"Jordan","clicks":109,"slug":"jordan"},{"brand":"Asics","clicks":95,"slug":"asics"},{"brand":"Diadora","clicks":34,"slug":"diadora"},{"brand":"Puma","clicks":21,"slug":"puma"},{"brand":"Vans","clicks":16,"slug":"vans"},{"brand":"New Balance","clicks":15,"slug":"new-balance"},{"brand":"Reebok","clicks":14,"slug":"reebok"},{"brand":"Converse","clicks":13,"slug":"converse"},{"brand":"Timberland","clicks":11,"slug":"timberland"},{"brand":"Saucony","clicks":8,"slug":"saucony"},{"brand":"Clarks","clicks":6,"slug":"clarks"},{"brand":"Filling Pieces","clicks":3,"slug":"filling-pieces"},{"brand":"Veja","clicks":0,"slug":"veja"},{"brand":"Toms","clicks":0,"slug":"toms"},{"brand":"On","clicks":0,"slug":"on"}],
    landingsMenu         : [{"url":"limited-editions","title":"Limited Editions"},{"url":"reseller","title":"Reseller"}],
    favorites            : [],
    notifications        : [],
    previousSearches     : [],
    chosenFavorite       : {},
    chosenForNotify      : {},
    initLoaded           : false,
    refreshing           : false,
    totalSneakersCount   : 61114,
    totalShopsCount      : 203,
    currentRouteMetas    : {},
    sneakerRouteHeader   : {},
    viewedSneakers       : [],
    cookiesAccept        : false,
    clipboardText        : '',
    serverError: false,
    serverErrorObj: {status: false},
    ttl: 0,
    categoryLanding: false,
    forceScrollTop: false,
    headerTopClass: [],
    countryCodes: ["IC","CY","ER","LR","BM","VA","CK","SO","ZM","VE","TM","AL","HR","GB","SD","TL","CG","AZ","KE","AS","CI","SN","VN","SV","AF","MF","LV","GT","KW","ST","KG","PL","GU","GH","LT","AM","JE","GD","TJ","ET","EH","MA","PR","CX","NZ","BN","GF","NU","RO","SJ","BY","PA","CM","CZ","BL","GR","ML","MQ","FR","PK","PE","BB","GL","PM","TD","HU","KM","BD","TK","FJ","CN","CO","VG","DZ","MV","MY","KY","ES","IE","EE","PY","UY","ZA","LC","VU","FI","SE","IO","LB","US","CL","NF","MS","AU","BZ","GY","MN","TV","DO","GQ","KN","BO","NP","TF","TW","BJ","BG","MD","IM","BT","KH","AG","HT","CV","GE","BI","BS","MU","LY","MW","MX","SZ","PG","DM","LI","RU","IL","SG","UG","SK","TO","AE","YT","SR","UZ","SA","EG","IT","MG","NC","CA","VI","MH","MR","GM","TT","SC","JP","BR","SY","SH","TZ","AD","IR","TG","MT","KR","WS","DE","NE","BV","JM","NI","GN","AI","AX","BE","PT","DK","PH","WF","AT","GW","MC","NA","UM","CR","BA","MO","MZ","RE","ME","KP","MP","UA","IQ","GS","AO","SL","FM","CU","TC","RS","EC","FO","AQ","PS","TR","KI","KZ","GI","IS","PW","QA","CH","PF","PN","JO","MM","TH","BQ","AW","GP","NG","BH","LA","CC","DJ","SB","HM","IN","SM","LU","SX","FK","CF","BW","CW","GG","NO","GA","ZW","LS","SI","AR","BF","YE","CD","OM","ID","NR","RW","MK","XK","NL","TN","SS","HN","VC","LK","HK"],
    countries:  [
        {'EUU': 'European Union'},
        {'AF': 'Afghanistan'},
        {'AX': 'Aland Islands'},
        {'AL': 'Albania'},
        {'DZ': 'Algeria'},
        {'AS': 'American Samoa'},
        {'AD': 'Andorra'},
        {'AO': 'Angola'},
        {'AI': 'Anguilla'},
        // {'AQ': 'Antarctica'},
        {'AG': 'Antigua And Barbuda'},
        {'AR': 'Argentina'},
        {'AM': 'Armenia'},
        {'AW': 'Aruba'},
        {'AU': 'Australia'},
        {'AT': 'Austria'},
        {'AZ': 'Azerbaijan'},
        {'BS': 'Bahamas'},
        {'BH': 'Bahrain'},
        {'BD': 'Bangladesh'},
        {'BB': 'Barbados'},
        {'BY': 'Belarus'},
        {'BE': 'Belgium'},
        {'BZ': 'Belize'},
        {'BJ': 'Benin'},
        {'BM': 'Bermuda'},
        {'BT': 'Bhutan'},
        {'BO': 'Bolivia'},
        {'BA': 'Bosnia And Herzegovina'},
        {'BW': 'Botswana'},
        {'BV': 'Bouvet Island'},
        {'BR': 'Brazil'},
        {'IO': 'British Indian Ocean Territory'},
        {'BN': 'Brunei Darussalam'},
        {'BG': 'Bulgaria'},
        {'BF': 'Burkina Faso'},
        {'BI': 'Burundi'},
        {'KH': 'Cambodia'},
        {'CM': 'Cameroon'},
        {'CA': 'Canada'},
        {'CV': 'Cape Verde'},
        {'KY': 'Cayman Islands'},
        {'CF': 'Central African Republic'},
        {'TD': 'Chad'},
        {'CL': 'Chile'},
        {'CN': 'China'},
        {'CX': 'Christmas Island'},
        {'CC': 'Cocos (Keeling) Islands'},
        {'CO': 'Colombia'},
        {'KM': 'Comoros'},
        {'CG': 'Congo'},
        {'CD': 'Congo, Democratic Republic'},
        {'CK': 'Cook Islands'},
        {'CR': 'Costa Rica'},
        {'CI': 'Cote D\'Ivoire'},
        {'HR': 'Croatia'},
        {'CU': 'Cuba'},
        {'CY': 'Cyprus'},
        {'CZ': 'Czech Republic'},
        {'DK': 'Denmark'},
        {'DJ': 'Djibouti'},
        {'DM': 'Dominica'},
        {'DO': 'Dominican Republic'},
        {'EC': 'Ecuador'},
        {'EG': 'Egypt'},
        {'SV': 'El Salvador'},
        {'GQ': 'Equatorial Guinea'},
        {'ER': 'Eritrea'},
        {'EE': 'Estonia'},
        {'ET': 'Ethiopia'},
        {'FK': 'Falkland Islands (Malvinas)'},
        {'FO': 'Faroe Islands'},
        {'FJ': 'Fiji'},
        {'FI': 'Finland'},
        {'FR': 'France'},
        {'GF': 'French Guiana'},
        {'PF': 'French Polynesia'},
        {'TF': 'French Southern Territories'},
        {'GA': 'Gabon'},
        {'GM': 'Gambia'},
        {'GE': 'Georgia'},
        {'DE': 'Germany'},
        {'GH': 'Ghana'},
        {'GI': 'Gibraltar'},
        {'GR': 'Greece'},
        {'GL': 'Greenland'},
        {'GD': 'Grenada'},
        {'GP': 'Guadeloupe'},
        {'GU': 'Guam'},
        {'GT': 'Guatemala'},
        {'GG': 'Guernsey'},
        {'GN': 'Guinea'},
        {'GW': 'Guinea-Bissau'},
        {'GY': 'Guyana'},
        {'HT': 'Haiti'},
        {'HM': 'Heard Island & Mcdonald Islands'},
        {'VA': 'Holy See (Vatican City State)'},
        {'HN': 'Honduras'},
        {'HK': 'Hong Kong'},
        {'HU': 'Hungary'},
        {'IS': 'Iceland'},
        {'IN': 'India'},
        {'ID': 'Indonesia'},
        {'IR': 'Iran, Islamic Republic Of'},
        {'IQ': 'Iraq'},
        {'IE': 'Ireland'},
        {'IM': 'Isle Of Man'},
        {'IL': 'Israel'},
        {'IT': 'Italy'},
        {'JM': 'Jamaica'},
        {'JP': 'Japan'},
        {'JE': 'Jersey'},
        {'JO': 'Jordan'},
        {'KZ': 'Kazakhstan'},
        {'KE': 'Kenya'},
        {'KI': 'Kiribati'},
        {'KR': 'Korea'},
        {'KW': 'Kuwait'},
        {'KG': 'Kyrgyzstan'},
        {'LA': 'Lao People\'s Democratic Republic'},
        {'LV': 'Latvia'},
        {'LB': 'Lebanon'},
        {'LS': 'Lesotho'},
        {'LR': 'Liberia'},
        {'LY': 'Libyan Arab Jamahiriya'},
        {'LI': 'Liechtenstein'},
        {'LT': 'Lithuania'},
        {'LU': 'Luxembourg'},
        {'MO': 'Macao'},
        {'MK': 'Macedonia'},
        {'MG': 'Madagascar'},
        {'MW': 'Malawi'},
        {'MY': 'Malaysia'},
        {'MV': 'Maldives'},
        {'ML': 'Mali'},
        {'MT': 'Malta'},
        {'MH': 'Marshall Islands'},
        {'MQ': 'Martinique'},
        {'MR': 'Mauritania'},
        {'MU': 'Mauritius'},
        {'YT': 'Mayotte'},
        {'MX': 'Mexico'},
        {'FM': 'Micronesia, Federated States Of'},
        {'MD': 'Moldova'},
        {'MC': 'Monaco'},
        {'MN': 'Mongolia'},
        {'ME': 'Montenegro'},
        {'MS': 'Montserrat'},
        {'MA': 'Morocco'},
        {'MZ': 'Mozambique'},
        {'MM': 'Myanmar'},
        {'NA': 'Namibia'},
        {'NR': 'Nauru'},
        {'NP': 'Nepal'},
        {'NL': 'Netherlands'},
        {'AN': 'Netherlands Antilles'},
        {'NC': 'New Caledonia'},
        {'NZ': 'New Zealand'},
        {'NI': 'Nicaragua'},
        {'NE': 'Niger'},
        {'NG': 'Nigeria'},
        {'NU': 'Niue'},
        {'NF': 'Norfolk Island'},
        {'MP': 'Northern Mariana Islands'},
        {'NO': 'Norway'},
        {'OM': 'Oman'},
        {'PK': 'Pakistan'},
        {'PW': 'Palau'},
        {'PS': 'Palestinian Territory, Occupied'},
        {'PA': 'Panama'},
        {'PG': 'Papua New Guinea'},
        {'PY': 'Paraguay'},
        {'PE': 'Peru'},
        {'PH': 'Philippines'},
        {'PN': 'Pitcairn'},
        {'PL': 'Poland'},
        {'PT': 'Portugal'},
        {'PR': 'Puerto Rico'},
        {'QA': 'Qatar'},
        {'RE': 'Reunion'},
        {'RO': 'Romania'},
        {'RU': 'Russian Federation'},
        {'RW': 'Rwanda'},
        {'BL': 'Saint Barthelemy'},
        {'SH': 'Saint Helena'},
        {'KN': 'Saint Kitts And Nevis'},
        {'LC': 'Saint Lucia'},
        {'MF': 'Saint Martin'},
        {'PM': 'Saint Pierre And Miquelon'},
        {'VC': 'Saint Vincent And Grenadines'},
        {'WS': 'Samoa'},
        {'SM': 'San Marino'},
        {'ST': 'Sao Tome And Principe'},
        {'SA': 'Saudi Arabia'},
        {'SN': 'Senegal'},
        {'RS': 'Serbia'},
        {'SC': 'Seychelles'},
        {'SL': 'Sierra Leone'},
        {'SG': 'Singapore'},
        {'SK': 'Slovakia'},
        {'SI': 'Slovenia'},
        {'SB': 'Solomon Islands'},
        {'SO': 'Somalia'},
        {'ZA': 'South Africa'},
        {'GS': 'South Georgia And Sandwich Isl.'},
        {'ES': 'Spain'},
        {'LK': 'Sri Lanka'},
        {'SD': 'Sudan'},
        {'SR': 'Suriname'},
        {'SJ': 'Svalbard And Jan Mayen'},
        {'SZ': 'Swaziland'},
        {'SE': 'Sweden'},
        {'CH': 'Switzerland'},
        {'SY': 'Syrian Arab Republic'},
        {'TW': 'Taiwan'},
        {'TJ': 'Tajikistan'},
        {'TZ': 'Tanzania'},
        {'TH': 'Thailand'},
        {'TL': 'Timor-Leste'},
        {'TG': 'Togo'},
        {'TK': 'Tokelau'},
        {'TO': 'Tonga'},
        {'TT': 'Trinidad And Tobago'},
        {'TN': 'Tunisia'},
        {'TR': 'Turkey'},
        {'TM': 'Turkmenistan'},
        {'TC': 'Turks And Caicos Islands'},
        {'TV': 'Tuvalu'},
        {'UG': 'Uganda'},
        {'UA': 'Ukraine'},
        {'AE': 'United Arab Emirates'},
        {'GB': 'United Kingdom'},
        {'US': 'United States'},
        {'UM': 'United States Outlying Islands'},
        {'UY': 'Uruguay'},
        {'UZ': 'Uzbekistan'},
        {'VU': 'Vanuatu'},
        {'VE': 'Venezuela'},
        {'VN': 'Viet Nam'},
        {'VG': 'Virgin Islands, British'},
        {'VI': 'Virgin Islands, U.S.'},
        {'WF': 'Wallis And Futuna'},
        {'EH': 'Western Sahara'},
        {'YE': 'Yemen'},
        {'ZM': 'Zambia'},
        {'ZW': 'Zimbabwe'},
      ],
});

export const mutations = {
    setReleaseDetailMoreProducts(state, data) {
        state.showReleaseDetailMoreProducts = data;
    },
    setGlobalDrawer(state, data) {
        state.showGlobalDrawer = data;
    },
    setGlobalDrawerSection(state, data) {
        state.globalDrawerSection = data
    },

    setReleaseListFilter(state, data) {
        state.showReleaseListFilter = data;
    },
    setSearchDrawer(state, data) {
        state.showSearchDrawer = data;
    },
    setShowPriceAlertDialog(state, data) {
        state.showPriceAlertDialog = data;
    },
    setSettingDrawer(state, data) {
        state.showSettingDrawer = data;
    },
    setShowMegaMenu(state, data) {
        state.showMegaMenu = data;
    },
    setMegaMenuIndex(state, data) {
        state.megaMenuIndex = data;
    },
    setGclid(state, data) {
        state.gclid = data;
    },
    setClientUid(state, data) {
        state.clientUid = data;
    },

    setCountry(state, data) {
        if(data && state.countryCodes.includes(data))
            state.location.code = data;
        else
            state.location.code = 'XX';
    },
    setMobileSearchDialogVisible(state, visible) {
        state.mobileSearchDialogVisible = visible;
    },
    setSettingDialogVisible(state, visible) {
        state.settingDialogVisible = visible;
    },
    setMobileDrawerVisible(state, visible) {
        state.mobileDrawerVisible = visible;
    },
    setLoadedSneakers(state, data)
    {
      state.loadedSneakers = data;
    },
    setLoadingMoreSneakers(state, data)
    {
      state.loadingMoreSneakers = data;
    },
    setHeaderTopClass(state, data)
    {
        state.headerTopClass = remove(state.headerTopClass, data.remove);
        if(indexOf(state.headerTopClass, data.add) === -1)
            state.headerTopClass.push(data.add);
    },
    setCategoryLanding(state, data)
    {
       state.categoryLanding = data;
    },
    setCurrency(state, data)
    {
      if(data && get(state.currencies, data.toUpperCase()))
          state.currency = data.toLowerCase();
    },
    acceptCookies(state) {
        state.cookiesAccept = true;
    },
    addViewedSneaker(state, data) {
        let viewedIndex = state.viewedSneakers.findIndex(el => el.slug === data.slug);
        if (viewedIndex > -1) {
            state.viewedSneakers.splice(viewedIndex, 1);
        }
        state.viewedSneakers.unshift(data);
        if (state.viewedSneakers.length > 20) {
            state.viewedSneakers.pop();
        }
    },
    setRouteMetas(state, data) {
        each(data, (value, key)=>{
            state.currentRouteMetas[key] = value;
        })
    },
    setSearchString(state, str) {
        state.searchString = str;
        state.queries.page = 1;
    },
    setSizeFormat(state, format) {
        state.sizeFormat = format
    },
    setForceScrollTop(state, data) {
        state.forceScrollTop = data;
    },
    changeQuery(state, data) {

        if(data.field === 'q')
            state.searchString = data.data;
        else
            set(state.queries, data.field, data.data);

        if (data.field !== 'page') state.queries.page = 1;
    },
    setQueryPage(state, data)
    {
        state.queries.page = (data ? data: 1);
    },
    setLandingQuery(state, data) {
       state.landingQuery = data;
    },
    setNextPage(state) {
        state.queries.page++
    },
    resetQueries(state) {
        state.queries = {
            filters: [],
            gender : [],
            sizes  : [],
            color  : [],
            brands : [],
            category: [],
            discount_from: 0,
            page   : 1,
            sort   : 'relevant'
        };
        state.searchString = '';
    },
    setLandingsMenu(state, data)
    {
        state.landingsMenu = data;
    },
    addMetas(state, data) {
        state[data.type][data.url] = {...state[data.type][data.url], ...data.data};
    },
    setOnceLoggedIn(state)
    {
        state.onceLoggedIn = true;
    },
    addFavorite(state, data) {
        state.favorites.push(data)
    },
    setFavorites(state, data) {
        state.favorites = data.map(el => el.slug)
    },
    removeFavorite(state, index) {
        state.favorites.splice(index, 1);
    },
    addNotification(state, data) {
        state.notifications.push(data);
    },
    setNotifications(state, data) {
        state.notifications = data.map(el => el.slug);
    },
    removeNotification(state, index) {
        state.notifications.splice(index, 1);
    },
    setChosenFavorite(state, data) {
        state.chosenFavorite = {...data};
    },
    setChosenForNotify(state, data) {
        state.chosenForNotify = {...data};
    },
    setPreviousSearches(state, data) {
        if (data) {
            if (state.previousSearches.includes(data)) {
                let index = state.previousSearches.indexOf(data);
                if (index > -1)
                    state.previousSearches.splice(index, 1);
            }
            state.previousSearches.unshift(data);
            if (state.previousSearches.length > 6) {
                state.previousSearches.pop();
            }
        }
    },
    deletePreviousSearch(state, data) {
        let index = state.previousSearches.indexOf(data);
        if (index > -1) {
            state.previousSearches.splice(index, 1);
        }
    },
    clearPreviousSearches(state) {
        state.previousSearches = [];
    },
    setBrands(state, data) {
        state.brands = data;
    },
    addBrandClick(state, brand) {
        let index = state.brands.findIndex((item) => {
            return item.brand === brand;
        });
        state.brands[index].clicks++;
        state.brands = orderBy(state.brands, 'clicks', 'desc')
    },
    setInitLoaded(state) {
        state.initLoaded = true;
    },
    setVisitorLocation(state, data) {
        state.location = {...data}
    },
    setTotalSneakersCount(state, data) {
        state.totalSneakersCount = data;
    },
    setTotalShopsCount(state, data) {
        state.totalShopsCount = data;
    },
    setRefreshing(state, data) {
        state.refreshing = data;
    },
    setTTL(state, data) {
        state.ttl = (new Date()).getTime() + (data-1)* 1000;
    },
    setClipboardText(state, data) {
        state.clipboardText = data;
    },
    setServerError(state, data) {
        state.serverError = data.status;
        state.serverErrorObj = {...data};
    },
    setSneakerPagesHeader(state, data)
    {
        state.sneakerRouteHeader = data;
    },

};

export const actions = {
    async openPriceAlertDialog({commit}) {
        commit('setShowPriceAlertDialog', true);
        document.body.style.overflow = 'hidden';
    },
    async closePriceAlertDialog({commit}) {
        commit('setShowPriceAlertDialog', false);
        document.body.style.overflow = 'auto';
    },
    async openReleaseDetailMoreProducts({commit}, data) {
        commit('setReleaseDetailMoreProducts', true);
        document.body.style.overflow = 'hidden';
    },
    async closeReleaseDetailMoreProducts({commit}) {
        commit('setReleaseDetailMoreProducts', false);
        document.body.style.overflow = 'auto';
    },
    async openGlobalDrawer({commit, state}, data) {
        if(state.showGlobalDrawer && state.globalDrawerSection === data){
            commit('setGlobalDrawer', false);
            commit('setGlobalDrawerSection', null)
            document.body.style.overflow = 'auto';
        }else{
            commit('setGlobalDrawer', true);
            commit('setGlobalDrawerSection', data)
            document.body.style.overflow = 'hidden';
        }
    },

    async closeGlobalDrawer({commit}) {
        commit('setGlobalDrawer', false);
        commit('setGlobalDrawerSection', null)
        document.body.style.overflow = 'auto';
    },

    async openMegaMenu({commit}, data) {
        commit('setSettingDrawer', false);
        commit('setSearchDrawer', false);
        commit('setShowMegaMenu', true);
        commit('setReleaseListFilter', false);
        commit('setMegaMenuIndex', data);
        document.body.style.overflow = 'hidden';
    },
    async closeMegaMenu({commit}) {
        commit('setShowMegaMenu', false);
        commit('setMegaMenuIndex', 0);
        document.body.style.overflow = 'auto';
    },
    async toggleSearchDrawer({state, commit}) {
        commit('setShowMegaMenu', false);
        commit('setMegaMenuIndex', 0);
        commit('setSettingDrawer', false);
        commit('setReleaseListFilter', false);
        commit('setSearchDrawer', !state.showSearchDrawer);
        document.body.style.overflow = state.showSearchDrawer ? 'hidden': 'auto';

    },
    async toggleSettingDrawer({state, commit}) {
        commit('setShowMegaMenu', false);
        commit('setMegaMenuIndex', 0);
        commit('setSearchDrawer', false);
        commit('setReleaseListFilter', false);
        commit('setSettingDrawer', !state.showSettingDrawer);
        document.body.style.overflow = state.showSettingDrawer ? 'hidden': 'auto';
    },
    async toggleReleaseListFilter({state, commit}) {
        commit('setShowMegaMenu', false);
        commit('setMegaMenuIndex', 0);
        commit('setSearchDrawer', false);
        commit('setSettingDrawer', false);
        commit('setReleaseListFilter', !state.showReleaseListFilter);
        document.body.style.overflow = state.showReleaseListFilter ? 'hidden': 'auto';
    },
    async setGclid({commit}, data) {
        commit('setGclid', data);
    },
    async setClientUid({commit}, data) {
        commit('setClientUid', data);
    },
    async openMobileDrawer({commit}) {
        commit('setMobileDrawerVisible', true);
    },
    async closeMobileDrawer({commit}) {
        commit('setMobileDrawerVisible', false);
    },
    async addViewedSneaker({state, commit}, data) {
        let viewedIndex = state.viewedSneakers.findIndex(el => el.slug === data.slug);
        if (viewedIndex === -1) {
            try {
                await this.$axios.post('view', {slug: data.slug})
            } catch (e) {
                console.log(e);
            }
        }
        commit('addViewedSneaker', data)
    },
    async refreshToken({state, commit, dispatch}) {
        commit('setRefreshing', true);
        try {
            if (process.client) {
                const oldToken = localStorage.getItem('auth._token.local');
                this.$axios.setHeader('Authorization', oldToken);
            }
            let {data} = await this.$axios.get('refresh');
            commit('setTTL', data.expires_in)
            commit('setRefreshing', false);
            return data.access_token;

        } catch (e) {
            console.log('refresh ', e.response ? e.response.status : e);
            commit('setRefreshing', false);
            dispatch('logout');
            return false;
        }

    },
    setSearchString({commit, dispatch}, data) {
        commit('resetQueries');
        commit('setSearchString', data);
        commit('setPreviousSearches', data);
    },
    async nuxtServerInit({state, commit, dispatch, $axios}, {req, res}) {
        try {
            console.log('state function works');

            const clientUidKey = 'SNK_CLIENT_UID';

            const clientUid = (req.headers && req.headers.cookie && req.headers.cookie.split(';').find(c => c.trim().startsWith(clientUidKey + '=')))
            let clientUidValue = clientUid && clientUid.includes('=') ? clientUid.split('=')[1] : null;

            if (!clientUidValue) {
                // 현재 시간을 기준으로 128비트의 UUID를 생성합니다.
                const date = new Date();
                clientUidValue = uuidv4();
                res.setHeader('Set-Cookie', `${clientUidKey}=${clientUidValue}; Path=/; Max-Age=31536000; HttpOnly; SameSite=Strict`);
            }

            commit('setClientUid', clientUidValue);

            let queryString = (req._parsedUrl && req._parsedUrl.search) ? req._parsedUrl.search : null;
            const gclidKey = 'SNK_GCLID';
            let queries = {};
            if(queryString){
                queryString = queryString.replace('?', '');
                const querySplit = queryString.split('&');
                querySplit.forEach(el => {
                    let pair = el.split('=');
                    queries[pair[0]] = pair[1];
                });
            }

            if(queries && queries.hasOwnProperty('gclid')) {
                const gclidValue = queries.gclid;
                console.log('gclidValue', gclidValue);
                //expires in 1 week
                res.setHeader('Set-Cookie', `${gclidKey}=${gclidValue}; Path=/; Max-Age=604800; HttpOnly; SameSite=Strict`);
                commit('setGclid', gclidValue);
            }



            // console.log('req', req);
            // let {data} = await this.$axios.get('init', {
            //     headers: {
            //         //cors headers change refferer to current domain
            //         'Referer': req.headers.host,
            //         'X-Requested-With': 'XMLHttpRequest',
            //         'origin': req.headers.host
            //     }
            // });
            //
            let {data} = await axios.get(process.env.apiUrl + 'init');
            // console.log(data);
            // // let {data} = await axios.get(process.env.apiUrl + 'init');


            if(data && data.hasOwnProperty('brands') && data.hasOwnProperty('releasesTotal') && data.hasOwnProperty('shopsTotal')){
                commit('setBrands', data.brands);
                commit('setTotalSneakersCount', data.releasesTotal);
                commit('setTotalShopsCount', data.shopsTotal);
            } else {
                commit('setBrands', []);
                commit('setTotalSneakersCount', 0);
                commit('setTotalShopsCount', 0);
            }



            if (!state.location.code || !state.currency || !state.location.nativeName) {
                const userAgent = req.headers['user-agent'];
                const isBot = isbot(userAgent);

                if(!isBot) {
                    const headers = req.headers;
                    // const ip = headers && headers.hasOwnProperty('cf-connecting-ip')
                    //     ? headers['cf-connecting-ip'] : null;

                    // if(headers){
                    //     console.log('headers', headers);
                    // }
                    let ipCountry = headers && headers.hasOwnProperty('cf-ipcountry')
                        ? headers['cf-ipcountry'] : null;

                    // if(ipCountry){
                    //     console.log('ipCountry', ipCountry);
                    // }
                    // const url = ip
                    //     ? process.env.apiUrl + 'v3/geo?ip=' + ip
                    //     : process.env.apiUrl + 'v3/geo';
                    // const geoResp = await axios.get(url);
                    // const geo = geoResp.data;
                    // const isoCode = geo.hasOwnProperty('iso_code') ? geo.iso_code : 'DE';


                    let ip = headers && headers.hasOwnProperty('cf-connecting-ip')
                        ? headers['cf-connecting-ip'] : null;


                    // console.log('ipCountry original: ', {
                    //     ip,
                    //     ipCountry
                    // });

                    if(typeof ip !== 'string' && ip !== null && ip.length > 0 ) {
                        ip = ip[0];
                    }
                    if(typeof ip === 'string') {
                        ip = ip.replace(/[\[\]"]+/g, '');
                    }



                    if(typeof ipCountry !== 'string' && ipCountry !== null && ipCountry.length > 0 ) {
                        ipCountry = ipCountry[0];
                    }

                    if(typeof ipCountry === 'string') {
                        //ipCountry = '["DE"]' => DE
                        ipCountry = ipCountry.replace(/[\[\]"]+/g, '');

                        // console.log('ipCountry init: ', `${ip} ${ipCountry}`);
                        await dispatch('getCountryInfo', ipCountry);
                    } else {
                        await dispatch('getCountryInfo', 'DE');
                    }
                }
                else
                    await dispatch('getCountryInfo','DE');
            }


            //get query params from url not req.query
            const querySplit = req.url.split('?');
            const query = querySplit.length > 1 ? querySplit[1] : null;
            const queryObj = {};
            if (query) {
                query.split('&').forEach(el => {
                    let pair = el.split('=');
                    queryObj[pair[0]] = pair[1];
                });
            }
            if(queryObj && queryObj.hasOwnProperty('country')) {
                await dispatch('getCountryInfo', queryObj.country.toUpperCase());
            }
            if(queryObj && queryObj.hasOwnProperty('currency')) {
                commit('setCurrency', queryObj.currency.toUpperCase());
            }

            commit('setInitLoaded');
        } catch (e) {
            console.log('init ', e.response ? e.response : e);
        }
    },
    async logout({state, commit}, local) {
        commit('setTTL', 0);
        if (local === true) {
            this.$auth.ctx.app.$axios.setHeader('Authorization', false);
            this.$auth.reset();
        } else {
            this.$auth.logout()
        }
    },

    async addFavourites({state, getters, commit, dispatch}, data) {
        const index = $nuxt._.indexOf(state.favorites, data.slug);
        if (index === -1) {
            try {
                await this.$axios.post('favorites', {
                    slug: data.slug
                });
                commit('addFavorite', data.slug);
            } catch (e) {
                console.log('fav ', e.response ? e.response.status : e);
                return Promise.reject(e);
            }
        } else {
            try {
                await this.$axios.delete('favorites/' + data.slug);
                commit('removeFavorite', index);
            } catch (e) {
                console.log('fav ', e.response ? e.response.status : e);
            }
        }
    },
    async removeNotification({state, commit, getters, dispatch}, slug) {
        try {
            await this.$axios.delete('price-alerts/' + slug);
            commit('removeNotification', $nuxt._.indexOf(state.notifications, slug))
        } catch (e) {
            console.log('alerts ', e.response ? e.response.status : e);
            return Promise.reject(e)
        }
    },
    async addSuggestionClick({state, commit, getters}, suggestionId) {
        try {
            await this.$axios.post(`suggestions/${suggestionId}/clicks/add`);
        } catch (e) {
            console.log('sugg click ', e.response ? e.response.status : e)
        }
    },
    async addBrandClick({state, commit, getters}, brand) {
        try {
            await this.$axios.post(`brands/${brand}/clicks/add`);
            commit('addBrandClick', brand)
        } catch (e) {
            console.log('brand click ', e.response ? e.response.status : e)
        }
    },
    async getUserLocation({dispatch}, ip) {
        
        const ipApiKey = process.env.ipApiKey;
        let ipApiUrl = 'http://api.ipapi.com/'+ip+'?access_key=';
        if(ip === '127.0.0.1')
            ipApiUrl = 'http://api.ipapi.com/check?access_key=';
        
        try {
            let location = await axios.get(ipApiUrl + ipApiKey,);
            let locationData = location.data;
            await dispatch('getCountryInfo', locationData.country_code)
        } catch (e) {
            console.log('ipapi ', e.response ? e.response.status : e);
            await dispatch('getCountryInfo', 'DE')
        }
    },
    async getCountryInfo({commit, state}, countryCode) {
        try {
            let response, countryInfo;

            countryInfo = require('../countries/' + countryCode + '.json');
            // if(!process.client)
            //     commit('setCurrency', countryInfo.currencies[0].code);
            let location = {
                code: countryCode && state.countryCodes.includes(countryCode) ? countryCode : 'XX',
                currency: countryInfo.currencies[0].code,
                euu: false,
                language: countryInfo.languages[0].iso639_1,
                nativeName: countryInfo.nativeName,
                name: countryInfo.name,
                names: {
                    en: countryInfo.name,
                    ...countryInfo.translations
                }
            };

            if(countryInfo.regionalBlocs){
                location.euu = countryInfo.regionalBlocs.findIndex(el => el.acronym === 'EU') > -1;
            }

            commit('setVisitorLocation', location);
        } catch (e) {
            console.log('restcountries (' + countryCode + ')', e.response ? e.response.status : e);
        }
    },
}

export const getters = {
    currency(state) {
        return state.currency
    },
    getSneakersQueryParams(state, getters) {
        let params = {
            currency: getters.currency,
            page    : state.queries.page,
            location: state.location.code,
            lang    : state.i18n.locale
        };
        if (state.queries.sort !== 'relevant') {
            params.sort = state.queries.sort;
        }
        if (state.queries.filters.length > 0) {
            state.queries.filters.forEach(el => {
                params[el] = true;
            });
        }
        for (let key in state.queries) {
            if (key !== 'filters' && key !== 'sort' && isArray(state.queries[key]) && state.queries[key].length > 0) {
                params[key] = state.queries[key].join(',')
            }
        }
        if(state.queries.discount_from > 1 ) {
            params['discount_from'] = state.queries.discount_from;
        }

        if (state.searchString) {
            params.q = state.searchString
        }
        return params
    },
}