export default function ({ store, route, params, $axios,app }) {

    let url = app.switchLocalePath('de');

    //?가 있는지 확인
    if(url.indexOf('?') !== -1) {
        const urls = url.split('?');
        url = urls[0];
    }

    return $axios.get('meta', { progress: false, params: {
        locale: store.state.i18n.locale,
        url: url
    }}).then(({data}) => {
        let metasObj = {
            title:  data.title,
            description: data.description,
            seo_description: data.seo_description
        };
        store.commit('setRouteMetas', metasObj);
         store.commit('setSneakerPagesHeader', {title: data.header_title, image: data.header_image});
    }).catch(e => {
        console.log('meta ', e.response ? e.response.status : e);
    })

}