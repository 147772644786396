const middleware = {}

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['loadMeta'] = require('../middleware/loadMeta.js')
middleware['loadMeta'] = middleware['loadMeta'].default || middleware['loadMeta']

middleware['loadMetaNonQuery'] = require('../middleware/loadMetaNonQuery.js')
middleware['loadMetaNonQuery'] = middleware['loadMetaNonQuery'].default || middleware['loadMetaNonQuery']

middleware['sneakersRoutes'] = require('../middleware/sneakersRoutes.js')
middleware['sneakersRoutes'] = middleware['sneakersRoutes'].default || middleware['sneakersRoutes']

export default middleware
