import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f7170076 = () => interopDefault(import('../pages/2m2m.vue' /* webpackChunkName: "pages/2m2m" */))
const _7d20bdfc = () => interopDefault(import('../pages/black-friday.vue' /* webpackChunkName: "pages/black-friday" */))
const _59f87161 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _8ec041c6 = () => interopDefault(import('../pages/data-protection.vue' /* webpackChunkName: "pages/data-protection" */))
const _d019395a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1f36c992 = () => interopDefault(import('../pages/go.vue' /* webpackChunkName: "pages/go" */))
const _017f6cca = () => interopDefault(import('../pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _0c801c36 = () => interopDefault(import('../pages/jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _619b066a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _89d6978e = () => interopDefault(import('../pages/models.vue' /* webpackChunkName: "pages/models" */))
const _016ad6f8 = () => interopDefault(import('../pages/new-go.vue' /* webpackChunkName: "pages/new-go" */))
const _dc4a8378 = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _621fea6a = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _c5f222fe = () => interopDefault(import('../pages/profile/my-account.vue' /* webpackChunkName: "pages/profile/my-account" */))
const _92f0eaaa = () => interopDefault(import('../pages/profile/my-favorites.vue' /* webpackChunkName: "pages/profile/my-favorites" */))
const _826bf288 = () => interopDefault(import('../pages/profile/my-notifications.vue' /* webpackChunkName: "pages/profile/my-notifications" */))
const _389b1b35 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _3bf5c770 = () => interopDefault(import('../pages/return-policy.vue' /* webpackChunkName: "pages/return-policy" */))
const _878b9d7e = () => interopDefault(import('../pages/shops/index.vue' /* webpackChunkName: "pages/shops/index" */))
const _2e0869f6 = () => interopDefault(import('../pages/story.vue' /* webpackChunkName: "pages/story" */))
const _14f80728 = () => interopDefault(import('../pages/team.vue' /* webpackChunkName: "pages/team" */))
const _41f12188 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _1ddd7e2d = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _5771ee54 = () => interopDefault(import('../pages/product/order/complete/_seq.vue' /* webpackChunkName: "pages/product/order/complete/_seq" */))
const _2399df2f = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _498a46c0 = () => interopDefault(import('../pages/product/checkout/_id.vue' /* webpackChunkName: "pages/product/checkout/_id" */))
const _a231989a = () => interopDefault(import('../pages/register/verify/_token.vue' /* webpackChunkName: "pages/register/verify/_token" */))
const _05e7f256 = () => interopDefault(import('../pages/contents/_contents/index.vue' /* webpackChunkName: "pages/contents/_contents/index" */))
const _8d18df4a = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _41d85cce = () => interopDefault(import('../pages/remove-alert/_uid.vue' /* webpackChunkName: "pages/remove-alert/_uid" */))
const _02ecc647 = () => interopDefault(import('../pages/shops/_shop/index.vue' /* webpackChunkName: "pages/shops/_shop/index" */))
const _7195af12 = () => interopDefault(import('../pages/sneaker/_.vue' /* webpackChunkName: "pages/sneaker/_" */))
const _9b3197fc = () => interopDefault(import('../pages/_product/index.vue' /* webpackChunkName: "pages/_product/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/2m2m",
    component: _f7170076,
    name: "2m2m___de"
  }, {
    path: "/black-friday",
    component: _7d20bdfc,
    name: "black-friday___de"
  }, {
    path: "/contact",
    component: _59f87161,
    name: "contact___de"
  }, {
    path: "/data-protection",
    component: _8ec041c6,
    name: "data-protection___de"
  }, {
    path: "/en",
    component: _d019395a,
    name: "index___en"
  }, {
    path: "/es",
    component: _d019395a,
    name: "index___es"
  }, {
    path: "/fr",
    component: _d019395a,
    name: "index___fr"
  }, {
    path: "/go",
    component: _1f36c992,
    name: "go___de"
  }, {
    path: "/imprint",
    component: _017f6cca,
    name: "imprint___de"
  }, {
    path: "/it",
    component: _d019395a,
    name: "index___it"
  }, {
    path: "/ja",
    component: _d019395a,
    name: "index___ja"
  }, {
    path: "/jobs",
    component: _0c801c36,
    name: "jobs___de"
  }, {
    path: "/ko",
    component: _d019395a,
    name: "index___ko"
  }, {
    path: "/login",
    component: _619b066a,
    name: "login___de"
  }, {
    path: "/models",
    component: _89d6978e,
    name: "models___de"
  }, {
    path: "/new-go",
    component: _016ad6f8,
    name: "new-go___de"
  }, {
    path: "/nl",
    component: _d019395a,
    name: "index___nl"
  }, {
    path: "/pl",
    component: _d019395a,
    name: "index___pl"
  }, {
    path: "/press",
    component: _dc4a8378,
    name: "press___de"
  }, {
    path: "/profile",
    component: _621fea6a,
    name: "profile___de",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___de"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___de"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___de"
    }]
  }, {
    path: "/pt",
    component: _d019395a,
    name: "index___pt"
  }, {
    path: "/register",
    component: _389b1b35,
    name: "register___de"
  }, {
    path: "/return-policy",
    component: _3bf5c770,
    name: "return-policy___de"
  }, {
    path: "/ro",
    component: _d019395a,
    name: "index___ro"
  }, {
    path: "/shops",
    component: _878b9d7e,
    name: "shops___de"
  }, {
    path: "/story",
    component: _2e0869f6,
    name: "story___de"
  }, {
    path: "/team",
    component: _14f80728,
    name: "team___de"
  }, {
    path: "/terms",
    component: _41f12188,
    name: "terms___de"
  }, {
    path: "/zh",
    component: _d019395a,
    name: "index___zh"
  }, {
    path: "/en/2m2m",
    component: _f7170076,
    name: "2m2m___en"
  }, {
    path: "/en/black-friday",
    component: _7d20bdfc,
    name: "black-friday___en"
  }, {
    path: "/en/contact",
    component: _59f87161,
    name: "contact___en"
  }, {
    path: "/en/data-protection",
    component: _8ec041c6,
    name: "data-protection___en"
  }, {
    path: "/en/go",
    component: _1f36c992,
    name: "go___en"
  }, {
    path: "/en/imprint",
    component: _017f6cca,
    name: "imprint___en"
  }, {
    path: "/en/jobs",
    component: _0c801c36,
    name: "jobs___en"
  }, {
    path: "/en/login",
    component: _619b066a,
    name: "login___en"
  }, {
    path: "/en/models",
    component: _89d6978e,
    name: "models___en"
  }, {
    path: "/en/new-go",
    component: _016ad6f8,
    name: "new-go___en"
  }, {
    path: "/en/press",
    component: _dc4a8378,
    name: "press___en"
  }, {
    path: "/en/profile",
    component: _621fea6a,
    name: "profile___en",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___en"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___en"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___en"
    }]
  }, {
    path: "/en/register",
    component: _389b1b35,
    name: "register___en"
  }, {
    path: "/en/return-policy",
    component: _3bf5c770,
    name: "return-policy___en"
  }, {
    path: "/en/shops",
    component: _878b9d7e,
    name: "shops___en"
  }, {
    path: "/en/story",
    component: _2e0869f6,
    name: "story___en"
  }, {
    path: "/en/team",
    component: _14f80728,
    name: "team___en"
  }, {
    path: "/en/terms",
    component: _41f12188,
    name: "terms___en"
  }, {
    path: "/es/2m2m",
    component: _f7170076,
    name: "2m2m___es"
  }, {
    path: "/es/black-friday",
    component: _7d20bdfc,
    name: "black-friday___es"
  }, {
    path: "/es/contact",
    component: _59f87161,
    name: "contact___es"
  }, {
    path: "/es/data-protection",
    component: _8ec041c6,
    name: "data-protection___es"
  }, {
    path: "/es/go",
    component: _1f36c992,
    name: "go___es"
  }, {
    path: "/es/imprint",
    component: _017f6cca,
    name: "imprint___es"
  }, {
    path: "/es/jobs",
    component: _0c801c36,
    name: "jobs___es"
  }, {
    path: "/es/login",
    component: _619b066a,
    name: "login___es"
  }, {
    path: "/es/models",
    component: _89d6978e,
    name: "models___es"
  }, {
    path: "/es/new-go",
    component: _016ad6f8,
    name: "new-go___es"
  }, {
    path: "/es/press",
    component: _dc4a8378,
    name: "press___es"
  }, {
    path: "/es/profile",
    component: _621fea6a,
    name: "profile___es",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___es"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___es"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___es"
    }]
  }, {
    path: "/es/register",
    component: _389b1b35,
    name: "register___es"
  }, {
    path: "/es/return-policy",
    component: _3bf5c770,
    name: "return-policy___es"
  }, {
    path: "/es/shops",
    component: _878b9d7e,
    name: "shops___es"
  }, {
    path: "/es/story",
    component: _2e0869f6,
    name: "story___es"
  }, {
    path: "/es/team",
    component: _14f80728,
    name: "team___es"
  }, {
    path: "/es/terms",
    component: _41f12188,
    name: "terms___es"
  }, {
    path: "/fr/2m2m",
    component: _f7170076,
    name: "2m2m___fr"
  }, {
    path: "/fr/black-friday",
    component: _7d20bdfc,
    name: "black-friday___fr"
  }, {
    path: "/fr/contact",
    component: _59f87161,
    name: "contact___fr"
  }, {
    path: "/fr/data-protection",
    component: _8ec041c6,
    name: "data-protection___fr"
  }, {
    path: "/fr/go",
    component: _1f36c992,
    name: "go___fr"
  }, {
    path: "/fr/imprint",
    component: _017f6cca,
    name: "imprint___fr"
  }, {
    path: "/fr/jobs",
    component: _0c801c36,
    name: "jobs___fr"
  }, {
    path: "/fr/login",
    component: _619b066a,
    name: "login___fr"
  }, {
    path: "/fr/models",
    component: _89d6978e,
    name: "models___fr"
  }, {
    path: "/fr/new-go",
    component: _016ad6f8,
    name: "new-go___fr"
  }, {
    path: "/fr/press",
    component: _dc4a8378,
    name: "press___fr"
  }, {
    path: "/fr/profile",
    component: _621fea6a,
    name: "profile___fr",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___fr"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___fr"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___fr"
    }]
  }, {
    path: "/fr/register",
    component: _389b1b35,
    name: "register___fr"
  }, {
    path: "/fr/return-policy",
    component: _3bf5c770,
    name: "return-policy___fr"
  }, {
    path: "/fr/shops",
    component: _878b9d7e,
    name: "shops___fr"
  }, {
    path: "/fr/story",
    component: _2e0869f6,
    name: "story___fr"
  }, {
    path: "/fr/team",
    component: _14f80728,
    name: "team___fr"
  }, {
    path: "/fr/terms",
    component: _41f12188,
    name: "terms___fr"
  }, {
    path: "/it/2m2m",
    component: _f7170076,
    name: "2m2m___it"
  }, {
    path: "/it/black-friday",
    component: _7d20bdfc,
    name: "black-friday___it"
  }, {
    path: "/it/contact",
    component: _59f87161,
    name: "contact___it"
  }, {
    path: "/it/data-protection",
    component: _8ec041c6,
    name: "data-protection___it"
  }, {
    path: "/it/go",
    component: _1f36c992,
    name: "go___it"
  }, {
    path: "/it/imprint",
    component: _017f6cca,
    name: "imprint___it"
  }, {
    path: "/it/jobs",
    component: _0c801c36,
    name: "jobs___it"
  }, {
    path: "/it/login",
    component: _619b066a,
    name: "login___it"
  }, {
    path: "/it/models",
    component: _89d6978e,
    name: "models___it"
  }, {
    path: "/it/new-go",
    component: _016ad6f8,
    name: "new-go___it"
  }, {
    path: "/it/press",
    component: _dc4a8378,
    name: "press___it"
  }, {
    path: "/it/profile",
    component: _621fea6a,
    name: "profile___it",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___it"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___it"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___it"
    }]
  }, {
    path: "/it/register",
    component: _389b1b35,
    name: "register___it"
  }, {
    path: "/it/return-policy",
    component: _3bf5c770,
    name: "return-policy___it"
  }, {
    path: "/it/shops",
    component: _878b9d7e,
    name: "shops___it"
  }, {
    path: "/it/story",
    component: _2e0869f6,
    name: "story___it"
  }, {
    path: "/it/team",
    component: _14f80728,
    name: "team___it"
  }, {
    path: "/it/terms",
    component: _41f12188,
    name: "terms___it"
  }, {
    path: "/ja/2m2m",
    component: _f7170076,
    name: "2m2m___ja"
  }, {
    path: "/ja/black-friday",
    component: _7d20bdfc,
    name: "black-friday___ja"
  }, {
    path: "/ja/contact",
    component: _59f87161,
    name: "contact___ja"
  }, {
    path: "/ja/data-protection",
    component: _8ec041c6,
    name: "data-protection___ja"
  }, {
    path: "/ja/go",
    component: _1f36c992,
    name: "go___ja"
  }, {
    path: "/ja/imprint",
    component: _017f6cca,
    name: "imprint___ja"
  }, {
    path: "/ja/jobs",
    component: _0c801c36,
    name: "jobs___ja"
  }, {
    path: "/ja/login",
    component: _619b066a,
    name: "login___ja"
  }, {
    path: "/ja/models",
    component: _89d6978e,
    name: "models___ja"
  }, {
    path: "/ja/new-go",
    component: _016ad6f8,
    name: "new-go___ja"
  }, {
    path: "/ja/press",
    component: _dc4a8378,
    name: "press___ja"
  }, {
    path: "/ja/profile",
    component: _621fea6a,
    name: "profile___ja",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___ja"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___ja"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___ja"
    }]
  }, {
    path: "/ja/register",
    component: _389b1b35,
    name: "register___ja"
  }, {
    path: "/ja/return-policy",
    component: _3bf5c770,
    name: "return-policy___ja"
  }, {
    path: "/ja/shops",
    component: _878b9d7e,
    name: "shops___ja"
  }, {
    path: "/ja/story",
    component: _2e0869f6,
    name: "story___ja"
  }, {
    path: "/ja/team",
    component: _14f80728,
    name: "team___ja"
  }, {
    path: "/ja/terms",
    component: _41f12188,
    name: "terms___ja"
  }, {
    path: "/ko/2m2m",
    component: _f7170076,
    name: "2m2m___ko"
  }, {
    path: "/ko/black-friday",
    component: _7d20bdfc,
    name: "black-friday___ko"
  }, {
    path: "/ko/contact",
    component: _59f87161,
    name: "contact___ko"
  }, {
    path: "/ko/data-protection",
    component: _8ec041c6,
    name: "data-protection___ko"
  }, {
    path: "/ko/go",
    component: _1f36c992,
    name: "go___ko"
  }, {
    path: "/ko/imprint",
    component: _017f6cca,
    name: "imprint___ko"
  }, {
    path: "/ko/jobs",
    component: _0c801c36,
    name: "jobs___ko"
  }, {
    path: "/ko/login",
    component: _619b066a,
    name: "login___ko"
  }, {
    path: "/ko/models",
    component: _89d6978e,
    name: "models___ko"
  }, {
    path: "/ko/new-go",
    component: _016ad6f8,
    name: "new-go___ko"
  }, {
    path: "/ko/press",
    component: _dc4a8378,
    name: "press___ko"
  }, {
    path: "/ko/profile",
    component: _621fea6a,
    name: "profile___ko",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___ko"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___ko"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___ko"
    }]
  }, {
    path: "/ko/register",
    component: _389b1b35,
    name: "register___ko"
  }, {
    path: "/ko/return-policy",
    component: _3bf5c770,
    name: "return-policy___ko"
  }, {
    path: "/ko/shops",
    component: _878b9d7e,
    name: "shops___ko"
  }, {
    path: "/ko/story",
    component: _2e0869f6,
    name: "story___ko"
  }, {
    path: "/ko/team",
    component: _14f80728,
    name: "team___ko"
  }, {
    path: "/ko/terms",
    component: _41f12188,
    name: "terms___ko"
  }, {
    path: "/nl/2m2m",
    component: _f7170076,
    name: "2m2m___nl"
  }, {
    path: "/nl/black-friday",
    component: _7d20bdfc,
    name: "black-friday___nl"
  }, {
    path: "/nl/contact",
    component: _59f87161,
    name: "contact___nl"
  }, {
    path: "/nl/data-protection",
    component: _8ec041c6,
    name: "data-protection___nl"
  }, {
    path: "/nl/go",
    component: _1f36c992,
    name: "go___nl"
  }, {
    path: "/nl/imprint",
    component: _017f6cca,
    name: "imprint___nl"
  }, {
    path: "/nl/jobs",
    component: _0c801c36,
    name: "jobs___nl"
  }, {
    path: "/nl/login",
    component: _619b066a,
    name: "login___nl"
  }, {
    path: "/nl/models",
    component: _89d6978e,
    name: "models___nl"
  }, {
    path: "/nl/new-go",
    component: _016ad6f8,
    name: "new-go___nl"
  }, {
    path: "/nl/press",
    component: _dc4a8378,
    name: "press___nl"
  }, {
    path: "/nl/profile",
    component: _621fea6a,
    name: "profile___nl",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___nl"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___nl"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___nl"
    }]
  }, {
    path: "/nl/register",
    component: _389b1b35,
    name: "register___nl"
  }, {
    path: "/nl/return-policy",
    component: _3bf5c770,
    name: "return-policy___nl"
  }, {
    path: "/nl/shops",
    component: _878b9d7e,
    name: "shops___nl"
  }, {
    path: "/nl/story",
    component: _2e0869f6,
    name: "story___nl"
  }, {
    path: "/nl/team",
    component: _14f80728,
    name: "team___nl"
  }, {
    path: "/nl/terms",
    component: _41f12188,
    name: "terms___nl"
  }, {
    path: "/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___de"
  }, {
    path: "/pl/2m2m",
    component: _f7170076,
    name: "2m2m___pl"
  }, {
    path: "/pl/black-friday",
    component: _7d20bdfc,
    name: "black-friday___pl"
  }, {
    path: "/pl/contact",
    component: _59f87161,
    name: "contact___pl"
  }, {
    path: "/pl/data-protection",
    component: _8ec041c6,
    name: "data-protection___pl"
  }, {
    path: "/pl/go",
    component: _1f36c992,
    name: "go___pl"
  }, {
    path: "/pl/imprint",
    component: _017f6cca,
    name: "imprint___pl"
  }, {
    path: "/pl/jobs",
    component: _0c801c36,
    name: "jobs___pl"
  }, {
    path: "/pl/login",
    component: _619b066a,
    name: "login___pl"
  }, {
    path: "/pl/models",
    component: _89d6978e,
    name: "models___pl"
  }, {
    path: "/pl/new-go",
    component: _016ad6f8,
    name: "new-go___pl"
  }, {
    path: "/pl/press",
    component: _dc4a8378,
    name: "press___pl"
  }, {
    path: "/pl/profile",
    component: _621fea6a,
    name: "profile___pl",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___pl"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___pl"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___pl"
    }]
  }, {
    path: "/pl/register",
    component: _389b1b35,
    name: "register___pl"
  }, {
    path: "/pl/return-policy",
    component: _3bf5c770,
    name: "return-policy___pl"
  }, {
    path: "/pl/shops",
    component: _878b9d7e,
    name: "shops___pl"
  }, {
    path: "/pl/story",
    component: _2e0869f6,
    name: "story___pl"
  }, {
    path: "/pl/team",
    component: _14f80728,
    name: "team___pl"
  }, {
    path: "/pl/terms",
    component: _41f12188,
    name: "terms___pl"
  }, {
    path: "/pt/2m2m",
    component: _f7170076,
    name: "2m2m___pt"
  }, {
    path: "/pt/black-friday",
    component: _7d20bdfc,
    name: "black-friday___pt"
  }, {
    path: "/pt/contact",
    component: _59f87161,
    name: "contact___pt"
  }, {
    path: "/pt/data-protection",
    component: _8ec041c6,
    name: "data-protection___pt"
  }, {
    path: "/pt/go",
    component: _1f36c992,
    name: "go___pt"
  }, {
    path: "/pt/imprint",
    component: _017f6cca,
    name: "imprint___pt"
  }, {
    path: "/pt/jobs",
    component: _0c801c36,
    name: "jobs___pt"
  }, {
    path: "/pt/login",
    component: _619b066a,
    name: "login___pt"
  }, {
    path: "/pt/models",
    component: _89d6978e,
    name: "models___pt"
  }, {
    path: "/pt/new-go",
    component: _016ad6f8,
    name: "new-go___pt"
  }, {
    path: "/pt/press",
    component: _dc4a8378,
    name: "press___pt"
  }, {
    path: "/pt/profile",
    component: _621fea6a,
    name: "profile___pt",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___pt"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___pt"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___pt"
    }]
  }, {
    path: "/pt/register",
    component: _389b1b35,
    name: "register___pt"
  }, {
    path: "/pt/return-policy",
    component: _3bf5c770,
    name: "return-policy___pt"
  }, {
    path: "/pt/shops",
    component: _878b9d7e,
    name: "shops___pt"
  }, {
    path: "/pt/story",
    component: _2e0869f6,
    name: "story___pt"
  }, {
    path: "/pt/team",
    component: _14f80728,
    name: "team___pt"
  }, {
    path: "/pt/terms",
    component: _41f12188,
    name: "terms___pt"
  }, {
    path: "/ro/2m2m",
    component: _f7170076,
    name: "2m2m___ro"
  }, {
    path: "/ro/black-friday",
    component: _7d20bdfc,
    name: "black-friday___ro"
  }, {
    path: "/ro/contact",
    component: _59f87161,
    name: "contact___ro"
  }, {
    path: "/ro/data-protection",
    component: _8ec041c6,
    name: "data-protection___ro"
  }, {
    path: "/ro/go",
    component: _1f36c992,
    name: "go___ro"
  }, {
    path: "/ro/imprint",
    component: _017f6cca,
    name: "imprint___ro"
  }, {
    path: "/ro/jobs",
    component: _0c801c36,
    name: "jobs___ro"
  }, {
    path: "/ro/login",
    component: _619b066a,
    name: "login___ro"
  }, {
    path: "/ro/models",
    component: _89d6978e,
    name: "models___ro"
  }, {
    path: "/ro/new-go",
    component: _016ad6f8,
    name: "new-go___ro"
  }, {
    path: "/ro/press",
    component: _dc4a8378,
    name: "press___ro"
  }, {
    path: "/ro/profile",
    component: _621fea6a,
    name: "profile___ro",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___ro"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___ro"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___ro"
    }]
  }, {
    path: "/ro/register",
    component: _389b1b35,
    name: "register___ro"
  }, {
    path: "/ro/return-policy",
    component: _3bf5c770,
    name: "return-policy___ro"
  }, {
    path: "/ro/shops",
    component: _878b9d7e,
    name: "shops___ro"
  }, {
    path: "/ro/story",
    component: _2e0869f6,
    name: "story___ro"
  }, {
    path: "/ro/team",
    component: _14f80728,
    name: "team___ro"
  }, {
    path: "/ro/terms",
    component: _41f12188,
    name: "terms___ro"
  }, {
    path: "/zh/2m2m",
    component: _f7170076,
    name: "2m2m___zh"
  }, {
    path: "/zh/black-friday",
    component: _7d20bdfc,
    name: "black-friday___zh"
  }, {
    path: "/zh/contact",
    component: _59f87161,
    name: "contact___zh"
  }, {
    path: "/zh/data-protection",
    component: _8ec041c6,
    name: "data-protection___zh"
  }, {
    path: "/zh/go",
    component: _1f36c992,
    name: "go___zh"
  }, {
    path: "/zh/imprint",
    component: _017f6cca,
    name: "imprint___zh"
  }, {
    path: "/zh/jobs",
    component: _0c801c36,
    name: "jobs___zh"
  }, {
    path: "/zh/login",
    component: _619b066a,
    name: "login___zh"
  }, {
    path: "/zh/models",
    component: _89d6978e,
    name: "models___zh"
  }, {
    path: "/zh/new-go",
    component: _016ad6f8,
    name: "new-go___zh"
  }, {
    path: "/zh/press",
    component: _dc4a8378,
    name: "press___zh"
  }, {
    path: "/zh/profile",
    component: _621fea6a,
    name: "profile___zh",
    children: [{
      path: "my-account",
      component: _c5f222fe,
      name: "profile-my-account___zh"
    }, {
      path: "my-favorites",
      component: _92f0eaaa,
      name: "profile-my-favorites___zh"
    }, {
      path: "my-notifications",
      component: _826bf288,
      name: "profile-my-notifications___zh"
    }]
  }, {
    path: "/zh/register",
    component: _389b1b35,
    name: "register___zh"
  }, {
    path: "/zh/return-policy",
    component: _3bf5c770,
    name: "return-policy___zh"
  }, {
    path: "/zh/shops",
    component: _878b9d7e,
    name: "shops___zh"
  }, {
    path: "/zh/story",
    component: _2e0869f6,
    name: "story___zh"
  }, {
    path: "/zh/team",
    component: _14f80728,
    name: "team___zh"
  }, {
    path: "/zh/terms",
    component: _41f12188,
    name: "terms___zh"
  }, {
    path: "/en/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___en"
  }, {
    path: "/es/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___es"
  }, {
    path: "/fr/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___fr"
  }, {
    path: "/it/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___it"
  }, {
    path: "/ja/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___ja"
  }, {
    path: "/ko/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___ko"
  }, {
    path: "/nl/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___nl"
  }, {
    path: "/pl/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___pl"
  }, {
    path: "/pt/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___pt"
  }, {
    path: "/ro/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___ro"
  }, {
    path: "/zh/password/reset",
    component: _1ddd7e2d,
    name: "password-reset___zh"
  }, {
    path: "/",
    component: _d019395a,
    name: "index___de"
  }, {
    path: "/en/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___en"
  }, {
    path: "/es/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___es"
  }, {
    path: "/fr/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___fr"
  }, {
    path: "/it/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___it"
  }, {
    path: "/ja/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___ja"
  }, {
    path: "/ko/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___ko"
  }, {
    path: "/nl/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___nl"
  }, {
    path: "/pl/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___pl"
  }, {
    path: "/pt/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___pt"
  }, {
    path: "/ro/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___ro"
  }, {
    path: "/zh/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___zh"
  }, {
    path: "/en/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___en"
  }, {
    path: "/en/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___en"
  }, {
    path: "/en/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___en"
  }, {
    path: "/es/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___es"
  }, {
    path: "/es/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___es"
  }, {
    path: "/es/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___es"
  }, {
    path: "/fr/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___fr"
  }, {
    path: "/fr/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___fr"
  }, {
    path: "/fr/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___fr"
  }, {
    path: "/it/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___it"
  }, {
    path: "/it/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___it"
  }, {
    path: "/it/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___it"
  }, {
    path: "/ja/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___ja"
  }, {
    path: "/ja/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___ja"
  }, {
    path: "/ja/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___ja"
  }, {
    path: "/ko/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___ko"
  }, {
    path: "/ko/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___ko"
  }, {
    path: "/ko/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___ko"
  }, {
    path: "/nl/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___nl"
  }, {
    path: "/nl/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___nl"
  }, {
    path: "/nl/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___nl"
  }, {
    path: "/pl/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___pl"
  }, {
    path: "/pl/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___pl"
  }, {
    path: "/pl/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___pl"
  }, {
    path: "/product/order/complete/:seq?",
    component: _5771ee54,
    name: "product-order-complete-seq___de"
  }, {
    path: "/pt/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___pt"
  }, {
    path: "/pt/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___pt"
  }, {
    path: "/pt/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___pt"
  }, {
    path: "/ro/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___ro"
  }, {
    path: "/ro/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___ro"
  }, {
    path: "/ro/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___ro"
  }, {
    path: "/zh/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___zh"
  }, {
    path: "/zh/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___zh"
  }, {
    path: "/zh/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___zh"
  }, {
    path: "/en/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___en"
  }, {
    path: "/en/product/:id",
    component: _8d18df4a,
    name: "product-id___en"
  }, {
    path: "/en/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___en"
  }, {
    path: "/en/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___en"
  }, {
    path: "/es/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___es"
  }, {
    path: "/es/product/:id",
    component: _8d18df4a,
    name: "product-id___es"
  }, {
    path: "/es/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___es"
  }, {
    path: "/es/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___es"
  }, {
    path: "/fr/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___fr"
  }, {
    path: "/fr/product/:id",
    component: _8d18df4a,
    name: "product-id___fr"
  }, {
    path: "/fr/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___fr"
  }, {
    path: "/fr/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___fr"
  }, {
    path: "/it/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___it"
  }, {
    path: "/it/product/:id",
    component: _8d18df4a,
    name: "product-id___it"
  }, {
    path: "/it/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___it"
  }, {
    path: "/it/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___it"
  }, {
    path: "/ja/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___ja"
  }, {
    path: "/ja/product/:id",
    component: _8d18df4a,
    name: "product-id___ja"
  }, {
    path: "/ja/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___ja"
  }, {
    path: "/ja/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___ja"
  }, {
    path: "/ko/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___ko"
  }, {
    path: "/ko/product/:id",
    component: _8d18df4a,
    name: "product-id___ko"
  }, {
    path: "/ko/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___ko"
  }, {
    path: "/ko/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___ko"
  }, {
    path: "/nl/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___nl"
  }, {
    path: "/nl/product/:id",
    component: _8d18df4a,
    name: "product-id___nl"
  }, {
    path: "/nl/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___nl"
  }, {
    path: "/nl/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___nl"
  }, {
    path: "/password/reset/:token",
    component: _2399df2f,
    name: "password-reset-token___de"
  }, {
    path: "/pl/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___pl"
  }, {
    path: "/pl/product/:id",
    component: _8d18df4a,
    name: "product-id___pl"
  }, {
    path: "/pl/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___pl"
  }, {
    path: "/pl/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___pl"
  }, {
    path: "/product/checkout/:id?",
    component: _498a46c0,
    name: "product-checkout-id___de"
  }, {
    path: "/pt/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___pt"
  }, {
    path: "/pt/product/:id",
    component: _8d18df4a,
    name: "product-id___pt"
  }, {
    path: "/pt/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___pt"
  }, {
    path: "/pt/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___pt"
  }, {
    path: "/register/verify/:token?",
    component: _a231989a,
    name: "register-verify-token___de"
  }, {
    path: "/ro/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___ro"
  }, {
    path: "/ro/product/:id",
    component: _8d18df4a,
    name: "product-id___ro"
  }, {
    path: "/ro/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___ro"
  }, {
    path: "/ro/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___ro"
  }, {
    path: "/zh/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___zh"
  }, {
    path: "/zh/product/:id",
    component: _8d18df4a,
    name: "product-id___zh"
  }, {
    path: "/zh/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___zh"
  }, {
    path: "/zh/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___zh"
  }, {
    path: "/ja/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___ja"
  }, {
    path: "/zh/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___zh"
  }, {
    path: "/ko/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___ko"
  }, {
    path: "/ro/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___ro"
  }, {
    path: "/pl/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___pl"
  }, {
    path: "/nl/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___nl"
  }, {
    path: "/it/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___it"
  }, {
    path: "/pt/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___pt"
  }, {
    path: "/es/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___es"
  }, {
    path: "/fr/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___fr"
  }, {
    path: "/en/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___en"
  }, {
    path: "/contents/:contents",
    component: _05e7f256,
    name: "contents-contents___de"
  }, {
    path: "/en/:product",
    component: _9b3197fc,
    name: "product___en"
  }, {
    path: "/es/:product",
    component: _9b3197fc,
    name: "product___es"
  }, {
    path: "/fr/:product",
    component: _9b3197fc,
    name: "product___fr"
  }, {
    path: "/it/:product",
    component: _9b3197fc,
    name: "product___it"
  }, {
    path: "/ja/:product",
    component: _9b3197fc,
    name: "product___ja"
  }, {
    path: "/ko/:product",
    component: _9b3197fc,
    name: "product___ko"
  }, {
    path: "/nl/:product",
    component: _9b3197fc,
    name: "product___nl"
  }, {
    path: "/pl/:product",
    component: _9b3197fc,
    name: "product___pl"
  }, {
    path: "/product/:id",
    component: _8d18df4a,
    name: "product-id___de"
  }, {
    path: "/pt/:product",
    component: _9b3197fc,
    name: "product___pt"
  }, {
    path: "/remove-alert/:uid?",
    component: _41d85cce,
    name: "remove-alert-uid___de"
  }, {
    path: "/ro/:product",
    component: _9b3197fc,
    name: "product___ro"
  }, {
    path: "/shops/:shop",
    component: _02ecc647,
    name: "shops-shop___de"
  }, {
    path: "/zh/:product",
    component: _9b3197fc,
    name: "product___zh"
  }, {
    path: "/sneaker/*",
    component: _7195af12,
    name: "sneaker-all___de"
  }, {
    path: "/:product",
    component: _9b3197fc,
    name: "product___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
