import Vue from 'vue';

import {
  LayoutPlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  TabsPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  ButtonToolbarPlugin,
  NavPlugin,
  NavbarPlugin,
  PaginationNavPlugin,
  ProgressPlugin,
  ModalPlugin,
  AlertPlugin,
  FormInputPlugin,
  ListGroupPlugin
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(TabsPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(ButtonToolbarPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(PaginationNavPlugin);
Vue.use(ProgressPlugin);
Vue.use(ModalPlugin);
Vue.use(AlertPlugin);
Vue.use(FormInputPlugin);
Vue.use(ListGroupPlugin);
