import {state} from "@/store";


export function priceFormat(value, currency = 'EUR', decimals = 2) {
    if (value === undefined || value === null) {
        return "";
    }
    if (typeof value === "string") {
        value = parseFloat(value);
    }
    value = value.toFixed(decimals);
    currency = currency ? currency.toUpperCase() : 'EUR';

    if(["KRW", "JPY"].includes(currency)) {
        value = Math.round(value);
    }

    if(value >= 1000) {
        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return `${currencySymbol(currency)}${value}`;

}
export function currencySymbol(currency) {
    currency = currency ? currency.toUpperCase() : state.currency.toUpperCase();
    switch (currency) {
        case "USD":
            return "$";
        case "EUR":
            return "€";
        case "GBP":
            return "£";
        case "JPY":
            return "¥";
        case "KRW":
            return "₩";
        default:
            return "$";
    }
}
export function getCountry(code) {

    if(!code) {
        code = 'XX';
    }
    return {
        code: code,
        flag: `/assets/icons/ui/flags/${code.toLowerCase()}.svg`,
        name: `countries.common.${code}`
    }
}

export function getCountryList(countryCodes) {
    const countries = [];

    for (let i = 0; i < countryCodes.length; i++) {
        countries.push(getCountry(countryCodes[i]));
    }
    return countries;
}

export function numberFormat(value, decimals = 2) {
    if (value === undefined || value === null) {
        return "";
    }
    if (typeof value === "string") {
        value = parseFloat(value);
    }
    value = value.toFixed(decimals);

    if(value >= 1000) {
        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return value;
}

export function goToTop() {
    window.scrollTo(0, 0);
}